import React from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import type { RouteComponentProps } from 'react-router-dom';
import { Alert, Button, Input, message } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import AuthLayout from 'common/layouts/AuthLayout';

const newPasswordValidation = Yup.object()
  .shape({
    Username: Yup.string().label('Username').required(),
    verificationCode: Yup.string().label('Verification Code').required(),
    newPassword: Yup.string().label('New Password').min(8).required(),
  })
  .noUnknown(true);

function ResetPassword(props: RouteComponentProps) {
  const { history, match } = props;
  return (
    <Formik
      onSubmit={async (values, formikHelpers) => {
        const resp = await fetch('/confirm-password', {
          method: 'POST',
          body: JSON.stringify(newPasswordValidation.cast(values)),
          headers: { 'Content-Type': 'application/json' },
        });
        if (!resp.ok) {
          const error = await resp.json();
          formikHelpers.setErrors({ global: error.message });
        } else {
          message.success({ content: 'Password Changed' });
          setTimeout(() => {
            history.push('/login');
          }, 2000);
        }
        formikHelpers.setSubmitting(false);
      }}
      validationSchema={newPasswordValidation}
      initialValues={{
        Username: match.params.user,
        verificationCode: '',
        newPassword: '',
      }}
    >
      {(formikHelpers) => {
        return (
          <AuthLayout>
            {formikHelpers.errors.global ? (
              <Alert message={formikHelpers.errors.global} type="error" />
            ) : null}
            <Field name="Username">
              {({ field, meta: { touched, error } }) => {
                return (
                  <FormItem
                    required
                    label="Username"
                    help={touched && error}
                    validateStatus={touched && error ? 'error' : 'success'}
                  >
                    <Input {...field} disabled autoComplete="username" />
                  </FormItem>
                );
              }}
            </Field>
            <Field name="verificationCode">
              {({ field, meta: { touched, error } }) => {
                return (
                  <FormItem
                    required
                    label="Reset Code"
                    help={touched && error}
                    validateStatus={touched && error ? 'error' : 'success'}
                  >
                    <Input {...field} autoComplete="one-time-code" />
                  </FormItem>
                );
              }}
            </Field>
            <Field name="newPassword">
              {({ field, meta: { touched, error } }) => {
                return (
                  <FormItem
                    required
                    label="New Password"
                    help={touched && error}
                    validateStatus={touched && error ? 'error' : 'success'}
                  >
                    <Input.Password {...field} autoComplete="new-password" />
                  </FormItem>
                );
              }}
            </Field>
            <Button
              block
              loading={formikHelpers.isSubmitting}
              disabled={formikHelpers.isSubmitting}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Confirm
            </Button>
          </AuthLayout>
        );
      }}
    </Formik>
  );
}

export default ResetPassword;
