import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from '../constants';

import { clearUser } from './user';
import { callApi } from '../util/createFetch';

const setSession = (result) => {
  const { token } = result;
  localStorage.setItem('id_token', token);
  sessionStorage.setItem('initialized', true);
};

export const removeSession = () => async (dispatch) => {
  localStorage.clear();
  sessionStorage.clear();
  await dispatch(clearUser());
  try {
    await callApi('api/v2/oauth2/sessions/logout', 'POST');
    return dispatch({ type: LOGOUT_SUCCESS });
  } catch (e) {
    console.error(e);
    return dispatch({ type: 'LOGOUT_FAILURE' });
  }
};

export function loginSuccess(token) {
  return {
    type: LOGIN_SUCCESS,
    token,
  };
}

export function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    timestamp: new Date(),
    error,
  };
}

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginUser = (credentials) => async (dispatch) => {
  dispatch(loginRequest());
  const { username, password } = credentials;
  try {
    const { token, error, errorData } = await callApi(
      'api/v2/oauth2/token/',
      'POST',
      {
        username,
        password,
      },
    );

    if (error || errorData) {
      if (errorData) {
        return dispatch(loginFailure(errorData));
      }
      return dispatch(loginFailure(error));
    }
    setSession({ token });
    return dispatch(loginSuccess(token));
  } catch (e) {
    return dispatch(loginFailure('An error occured on the server.'));
  }
};
