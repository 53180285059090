import React from 'react';
import AntForm from 'antd/lib/form';
import type { FormProps } from 'antd/lib/form';
import { Form as FmkForm, useFormikContext } from 'formik';

export type FormikFormProps = React.FormHTMLAttributes<HTMLFormElement> & {
  innerRef?: React.RefObject<typeof AntForm>,
};

function Form(props: FormikFormProps & FormProps) {
  const { action, innerRef, ...rest } = props;
  const { handleReset, handleSubmit, ...fmk } = useFormikContext();

  return (
    <AntForm
      // ref={innerRef}
      action={action || '#'}
      component={FmkForm}
      // onSubmit={handleSubmit}
      onReset={handleReset}
      onFinish={() => fmk.submitForm()}
      {...rest}
    />
  );
}

export default Form;
