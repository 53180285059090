import { callApi } from '../util/createFetch';
import {
  RECEIVE_CURRENT_USER,
  REQUEST_CURRENT_USER,
  CURRENT_USER_FAILURE,
  CLEAR_CURRENT_USER,
  EDIT_CURRENT_USER_FAILURE,
  EDIT_CURRENT_USER_SUCCESS,
  EDIT_CURRENT_USER_REQUEST,
} from '../constants';

function clearUser() {
  return {
    type: CLEAR_CURRENT_USER,
  };
}

function requestUser() {
  return {
    type: REQUEST_CURRENT_USER,
  };
}

function requestUserFailure(error) {
  return {
    error,
    timestamp: new Date(),
    type: CURRENT_USER_FAILURE,
  };
}

function receiveUser(user) {
  return {
    type: RECEIVE_CURRENT_USER,
    user,
  };
}

const fetchCurrentUser = () => async (dispatch) => {
  dispatch(requestUser());
  try {
    const response = await callApi('/api/v2/current-user');
    if (response.error) {
      if (response.url) {
        window.location.replace(response.url);
      }
      return dispatch(requestUserFailure(response.errorData || response.error));
    }
    return dispatch(receiveUser(response));
  } catch (e) {
    return dispatch(requestUserFailure(e.message));
  }
};

function shouldFetchUser(state) {
  const { auth, user } = state;
  return auth.authenticated && !user.uuid;
}

const fetchUserIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  if (shouldFetchUser(state)) {
    dispatch(requestUser());
    dispatch(fetchCurrentUser());
  }
};

// Edit
function editUserFailure(error) {
  return {
    type: EDIT_CURRENT_USER_FAILURE,
    timestamp: new Date(),
    isEditing: false,
    error,
  };
}

function editUserSuccess(user) {
  return {
    type: EDIT_CURRENT_USER_SUCCESS,
    user,
  };
}

const requestEditUser = (user) => ({
  type: EDIT_CURRENT_USER_REQUEST,
  user,
});

const editUserRequest = (data) => async (dispatch) => {
  dispatch(requestEditUser(data));
  try {
    const result = await callApi('/api/v2/current-user', 'POST', data);
    if (result.errors) {
      dispatch(editUserFailure(result.errors));
      return { errors: result.errors };
    }
    dispatch(editUserSuccess(result));
    return { result };
  } catch (err) {
    console.error(err);
    dispatch(editUserFailure(err));
    return { errors: err };
  }
};

export {
  clearUser,
  fetchCurrentUser,
  fetchUserIfNeeded,
  editUserFailure,
  editUserSuccess,
  requestEditUser,
  editUserRequest,
};

export default fetchUserIfNeeded;
