import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ForgotPassword from 'routes/auth/components/ForgotPassword';
import type { FormikProps } from 'formik';
import { Alert, Col, Layout, Row } from 'antd';

import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';

import styles from './LoginRoute.module.less';

interface Props extends FormikProps {
  removeSession: () => void;
  apiStartPoll: () => void;
  apiEndPoll: () => void;
  requestLogin: () => void;
  logout: () => void;
  newUser: () => void;
  error?: string;
  timestamp?: string;
  isFetching?: boolean;
  authenticated: boolean;
  history: any;
  location: any;
}

type FormatErrorArgs = { message?: string, status?: string } | string | any;

function formatError(errorData: FormatErrorArgs): string {
  if (typeof errorData === 'string') {
    return errorData;
  }

  return (
    errorData.message || errorData.status || 'An unknown error has occurred.'
  );
}

class LoginRoute extends React.PureComponent<Props> {
  componentDidMount() {
    const { apiStartPoll, removeSession } = this.props;
    removeSession();
    apiStartPoll();
  }

  componentDidUpdate() {
    const { runtime, logout, history } = this.props;
    if (runtime.initialized === false) {
      logout();
      history.push('/setup');
    }
  }

  async componentWillUnmount() {
    const { apiEndPoll } = this.props;
    apiEndPoll();
  }

  render() {
    const { error } = this.props;

    return (
      <Layout className={styles.loginContainer}>
        <Layout.Content>
          <Row>
            <Col span={12} offset={6}>
              <img
                className={styles.logo}
                src="/static/img/SVG/banner-black.svg"
                alt="logo"
              />
              {error && (
                <Alert
                  style={{ textAlign: 'left' }}
                  message="Error"
                  type="error"
                  description={formatError(error)}
                  showIcon
                />
              )}
            </Col>
          </Row>
          <Switch>
            <Route path="/forgot-password/:user" component={ResetPassword} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/login" component={LoginForm} />
            <Redirect to="/login" />
          </Switch>
        </Layout.Content>
      </Layout>
    );
  }
}

export default LoginRoute;
