import React, { PureComponent } from 'react';
import { Spin, Alert } from 'antd';

type Props = {
  hide?: boolean,
  message: string,
  extra?: ReactNode,
  indicator?: ReactNode,
  children: ReactNode,
};
class ErrorSpinBanner extends PureComponent<Props> {
  render() {
    const { hide, message, extra, indicator, children } = this.props;

    return (
      <Spin
        spinning={!hide}
        size="large"
        indicator={indicator}
        tip={<Alert message={message} description={extra} type="error" />}
      >
        {children}
      </Spin>
    );
  }
}
ErrorSpinBanner.defaultProps = {
  hide: false,
  extra: undefined,
  indicator: undefined,
};

export default ErrorSpinBanner;
