import {
  SET_RUNTIME_VARIABLE,
  CHECK_INITIALIZED_REQUEST,
  CHECK_INITIALIZED_FAILURE,
  CHECK_INITIALIZED_SUCCESS,
} from '../constants';
import { callApi } from '../util/createFetch';

export const setRuntimeVariable = ({ name, value }) => ({
  type: SET_RUNTIME_VARIABLE,
  payload: {
    name,
    value,
  },
});

const checkIfInitializedRequest = () => ({
  type: CHECK_INITIALIZED_REQUEST,
});

const checkIfInitializedSuccess = (initialized) => ({
  type: CHECK_INITIALIZED_SUCCESS,
  initialized,
});

const checkIfInitializedFailure = (error) => ({
  type: CHECK_INITIALIZED_FAILURE,
  timestamp: Date.now(),
  error,
});

export const apiStartPoll = () => async (dispatch) => {
  dispatch({
    type: 'API_STATUS_START',
    payload: {
      options: { interval: 3 * 1000, maxAttempts: -1 },
    },
  });
};
export const apiEndPoll = () => async (dispatch) => {
  dispatch({
    type: 'API_STATUS_END',
  });
};

export const fetchAppInitialization = () => async (dispatch) => {
  dispatch(checkIfInitializedRequest());
  try {
    const { initialized, error } = await callApi('/api/v2/globals');
    if (error) {
      dispatch(checkIfInitializedFailure);
    }
    dispatch(checkIfInitializedSuccess(initialized));
  } catch (e) {
    dispatch(e);
  }
};
