import React from 'react';
import Form from 'components/Form';
import classNames from 'classnames/bind';
import styles from './AuthLayout.module.less';

type Props = { children: React.ReactNode, className?: string };

const cx = classNames.bind(styles);

function AuthLayout(props: Props) {
  const { children, className } = props;
  return (
    <div className={cx(styles.login, className)}>
      <Form className={styles.loginForm} layout="vertical">
        {children}
      </Form>
    </div>
  );
}

AuthLayout.defaultProps = {
  className: null,
};

export default AuthLayout;
