import React from 'react';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import type { RouteComponentProps } from 'react-router-dom';
import { Alert, Typography, Button, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { MailOutlined } from '@ant-design/icons';
import { callApi } from 'common/util/createFetch';
import AuthLayout from 'common/layouts/AuthLayout';

function EmailField() {
  const [field, meta] = useField({ name: 'email' });
  const { error, touched } = meta;

  return (
    <FormItem
      colon
      required
      label="Email Address"
      help={touched && error}
      validateStatus={touched && error ? 'error' : 'success'}
    >
      <Input
        {...field}
        placeholder="Email Address"
        size="large"
        prefix={
          <MailOutlined style={{ marginRight: 5, color: 'rgba(0,0,0,.25)' }} />
        }
      />
    </FormItem>
  );
}

const loginValidation = Yup.object()
  .shape({
    email: Yup.string()
      .label('Email Address')
      .email(({ label }) => `${label} must be valid`)
      .required(({ label }) => `${label} is required`),
  })
  .noUnknown(true);

function ForgotPassword(props: RouteComponentProps) {
  const { history } = props;
  return (
    <Formik
      onSubmit={async (values, formikHelpers) => {
        const resp = await callApi(
          '/forgot-password/new',
          'POST',
          loginValidation.cast(values),
        );
        if (resp.error) {
          if (resp.errorData) {
            formikHelpers.setErrors({ global: resp.errorData.message });
          } else {
            formikHelpers.setErrors({ global: resp.error });
          }
        } else {
          history.push(`/forgot-password/${values.email}`);
        }
        formikHelpers.setSubmitting(false);
      }}
      validationSchema={loginValidation}
      initialValues={{ username: '', password: '' }}
    >
      {(formikHelpers) => {
        return (
          <AuthLayout>
            <Typography>
              <Typography.Title>Forgot password?</Typography.Title>
              <Typography.Paragraph>
                Enter the email address associated with your account to send a
                reset password code.
              </Typography.Paragraph>
            </Typography>
            {formikHelpers.errors.global ? (
              <Alert message={formikHelpers.errors.global} type="error" />
            ) : null}
            <EmailField />
            <Button
              block
              loading={formikHelpers.isSubmitting}
              disabled={formikHelpers.isSubmitting}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Send Password Request
            </Button>
          </AuthLayout>
        );
      }}
    </Formik>
  );
}

export default ForgotPassword;
