import React from 'react';
import * as Yup from 'yup';
import { get } from 'lodash';
import { from, of } from 'rxjs';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useField } from 'formik';
import type { FormikProps } from 'formik';
import { Link } from 'react-router-dom';
import { parse as qsParse } from 'query-string';
import { catchError, switchMap } from 'rxjs/operators';
import type { RouteComponentProps } from 'react-router-dom';
import type { ArgsProps } from 'antd/lib/notification';
import { Button, Divider, Input, Modal, notification } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import {
  FrownFilled,
  LockOutlined as LockIcon,
  UserOutlined as UserIcon,
} from '@ant-design/icons';

import type { RootState } from 'common/reducers';
import type { AuthState } from 'common/reducers/auth';
import type { RuntimeState } from 'common/reducers/runtime';

import { loginUser } from 'common/actions/auth';
import AuthLayout from 'common/layouts/AuthLayout';
import ErrorSpinBanner from 'components/ErrorSpinBanner';
import styles from './LoginForm.module.less';

const errorModal = (title) => {
  return Modal.error({
    title,
    content: 'An error has occurred',
  });
};

function emitNotifications(notifications: ArgsProps[]) {
  notifications.forEach((conf, ix) => {
    setTimeout(
      () => notification.open({ duration: 2.5, ...conf }),
      (ix + 1) * 500,
    );
  });
}

function getRuntimeError(error) {
  if (
    error &&
    typeof error === 'object' &&
    Object.prototype.hasOwnProperty.call(error, 'code')
  ) {
    return `${error.code}: ${error.status || 'Api Error'}`;
  }

  return error;
}

function UsernameField() {
  const [field, meta] = useField<string>({ name: 'username' });
  const { touched, initialValue, error } = meta;

  return (
    <FormItem
      validateStatus={touched && error ? 'error' : 'success'}
      help={touched && error}
    >
      <Input
        {...field}
        autoComplete="off"
        prefix={<UserIcon style={{ color: 'rgba(0,0,0,.25)' }} />}
        defaultValue={initialValue}
        placeholder="Username"
        size="large"
      />
    </FormItem>
  );
}

function PasswordField() {
  const [field, meta] = useField<string>({ name: 'password' });
  const { touched, initialValue, error } = meta;

  return (
    <FormItem
      validateStatus={touched && error ? 'error' : 'success'}
      help={touched && error}
    >
      <Input.Password
        {...field}
        size="large"
        type="password"
        placeholder="Password"
        visibilityToggle={false}
        defaultValue={initialValue}
        autoComplete="password current-password"
        prefix={<LockIcon style={{ color: 'rgba(0,0,0,.25)' }} />}
      />
    </FormItem>
  );
}

function Login(props: FormikProps<{ username: string, password: string }>) {
  const { isSubmitting } = props;

  const runtime = useSelector<RootState, RuntimeState>(
    (state) => state.runtime,
  );
  const { isFetching } = useSelector<RootState, AuthState>(
    (state) => state.auth,
  );

  const registerUrl = React.useMemo(() => {
    const url = new URL(
      '/incoming',
      runtime.nomitallUrl || 'https://my.nomnomdata.com',
    );
    url.search = '?endpoint=web.user.register';
    return url;
  }, [runtime]);

  return (
    <ErrorSpinBanner
      hide={!runtime.error}
      indicator={<FrownFilled spin style={{ color: 'red' }} />}
      message="There was a problem reaching the Nominode API service."
      extra={
        <>
          <div>{getRuntimeError(runtime.error)}</div>
          <Button
            type="link"
            href="https://support.nomnomdata.com/portal/kb/articles/performing-debug-steps-on-a-nominode"
            target="_blank"
          >
            Need Help?
          </Button>
        </>
      }
    >
      <AuthLayout className={styles.loginContainer}>
        <UsernameField />
        <PasswordField />
        <Button
          loading={isSubmitting || isFetching}
          type="primary"
          htmlType="submit"
          className={styles.submit}
          size="large"
          block
        >
          Log In
        </Button>
        <div className={styles.other}>
          <Link
            to="/forgot-password"
            type="link"
            className={styles.forgotPassword}
          >
            Forgot Password?
          </Link>
          <Divider />
          <span>Don&apos;t have an account?</span>
          <Button
            type="link"
            rel="noopener"
            target="_blank"
            htmlType="button"
            href={registerUrl}
            disabled={!registerUrl}
            className={styles.register}
          >
            Register
          </Button>
        </div>
      </AuthLayout>
    </ErrorSpinBanner>
  );
}

const loginValidation = Yup.object().shape({
  username: Yup.string().required('Username must be provided!'),
  password: Yup.string().required('Password must be provided!'),
});

function LoginForm(props: RouteComponentProps) {
  const { location, history } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    const notifications = get(location.state, 'notifications');
    if (notifications) emitNotifications(notifications);
  }, []);

  return (
    <Formik
      component={Login}
      validationSchema={loginValidation}
      initialValues={{ username: '', password: '' }}
      onSubmit={(values, formikHelpers) => {
        const { search, state } = location;
        const next = state && state.referrer ? state.referrer : qsParse(search);

        const action$ = from(dispatch(loginUser(values))).pipe(
          switchMap((response) => {
            return of({
              error: response.statusText,
              status: response.status,
              ...response,
            });
          }),
          catchError((err) => {
            console.error(err);
            return of(new Error(err));
          }),
        );

        action$.subscribe({
          next: ({ token, error }) => {
            const errors = get(error, 'errors');
            if (errors) {
              formikHelpers.setErrors(errors);
            } else if (token) {
              history.push(next.referrer || '/tasks', {});
            }
          },
          complete: formikHelpers.setSubmitting(false),
          error: (err) => errorModal(err.toString()),
        });
      }}
    />
  );
}

export default LoginForm;
