import { connect } from 'react-redux';
import { clearUser } from 'common/actions/user';
import { loginUser, removeSession } from 'common/actions/auth';
import { apiStartPoll, apiEndPoll } from 'common/actions/runtime';

import LoginRoute from '../components/LoginRoute';

const mapStateToProps = ({ auth, runtime }) => ({
  ...auth,
  runtime,
});

export default connect(mapStateToProps, {
  requestLogin: loginUser,
  logout: removeSession,
  newUser: clearUser,
  removeSession,
  apiStartPoll,
  apiEndPoll,
})(LoginRoute);
